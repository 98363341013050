import { CategoryType } from 'modules/categories/types';
import { QuizContentType, QuizType } from 'modules/quizzes/types';
import { VacancyStatus } from 'modules/vacancy/types';

export const quizSelectType = [
  {
    name: 'Bir tanlovli',
    type: QuizType.SINGLE_SELECT,
  },
  {
    name: "Ko'p tanlovli",
    type: QuizType.MULTI_SELECT,
  },
  {
    name: 'Qisqa matn tanlovli',
    type: QuizType.SHORT_TEXT,
    disabled: true,
  },
  {
    name: "Ko'p matn tanlovli",
    type: QuizType.LONG_TEXT,
    disabled: true,
  },
  {
    name: 'Mos kelish tanlovli',
    type: QuizType.MATCH_PAIRS,
    disabled: true,
  },
  {
    name: 'Ketma-ket',
    type: QuizType.SEQUENCE,
    disabled: true,
  },
];

export const contentType = [
  {
    name: 'Text format',
    type: QuizContentType.TEXT,
  },
  {
    name: 'Rasm format',
    type: QuizContentType.PHOTO,
    disabled: true,
  },
  {
    name: 'Audio format',
    type: QuizContentType.AUDIO,
    disabled: true,
  },
];

export const vacancyStatus = [
  {
    title: 'Qabul qilindi',
    value: VacancyStatus.ACCEPTED,
  },
  {
    title: 'Rad etildi',
    value: VacancyStatus.REJECTED,
  },
  {
    title: 'Jarayonda',
    value: VacancyStatus.PENDING,
  },
];

export const vacancyStatusForm = [
  {
    title: 'Qabul qilish',
    value: VacancyStatus.ACCEPTED,
  },
  {
    title: 'Rad etish',
    value: VacancyStatus.REJECTED,
  },
];

export const categoryType = [
  {
    name: 'Shaxsiy rivojlanish',
    type: CategoryType.PERSONAL_DEVELOPMENT,
  },
  {
    name: "Kasbiy ta'lim",
    type: CategoryType.VOCATIONAL_TRAINING,
  },
];
