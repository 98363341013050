import { Story } from './types';
export const getStory = (item?: Story) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    link: item?.link ?? '',
  };
};

export const getStoriesList = (data?: Story[]) => {
  return data?.length
    ? data.map(item => {
        return getStory(item);
      })
    : [];
};
