import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form } from 'components/ui/form';
import { RichTextEditor } from 'components/fields';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { Label } from 'components/ui/label';
import { SubmittedResume } from 'modules/resume/type';
import { normalizeImgUrl } from 'utils/normalizeImgUrl';
import LoadingButton from 'components/LoadingButton';
import { useReplyResume } from 'modules/resume/hooks/useReplyResume';
import RadioGroupField from '../../components/fields/RadioGroup';
import { VacancyStatus } from 'modules/vacancy/types';
import { vacancyStatusForm } from 'constants/index';
import { FileText } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';

const resumeSchema = z.object({
  message: z.string().min(1, { message: 'Xabarni kiriting' }),
  status: z.nativeEnum(VacancyStatus),
});

type resumeFormShcema = z.infer<typeof resumeSchema>;

interface IProps {
  resume: SubmittedResume;
  setIsOpen: (state: boolean) => void;
}

export default function ResumeForm({ resume, setIsOpen }: IProps) {
  const { vacancyId } = useParams();
  const {
    resumes_info: { full_name, exprience, job_title, description, file },
    profiles_info: { image, user },
    vacancy_status,
  } = resume;
  const form = useForm<resumeFormShcema>({
    resolver: zodResolver(resumeSchema),
    defaultValues: {
      message: '',
      status: undefined,
    },
  });

  const { triggerReplyResume, isPending: isLoadingReject } = useReplyResume({
    vacancyId: vacancyId!,
    setIsOpen,
  });

  const onSubmit = async (formValues: resumeFormShcema) => {
    triggerReplyResume({ ...formValues, user });
  };

  return (
    <>
      <div className="flex flex-col gap-4 p-4 bg-white shadow-md rounded-lg">
        <div className="flex items-center space-x-4">
          <Avatar>
            <AvatarImage src={normalizeImgUrl(image)} alt="user image" />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <Label className="text-lg font-semibold">{full_name}</Label>
            <Label className="text-sm text-gray-500">{`${exprience}- Tajriba`}</Label>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Label className="text-base font-semibold">Kasbi: </Label>
          <Label className="text-sm text-gray-500">{job_title}</Label>
        </div>
        <div className="flex items-center space-x-4">
          <Label className="text-base font-semibold">
            Kandidatning tavsifi:
          </Label>
          <Label className="text-sm text-gray-500">{description}</Label>
        </div>
        <Link
          to={`${process.env.REACT_APP_BASE_URL}${file}`}
          target="_blank"
          className="flex items-center gap-2 border rounded-md w-1/2 cursor-pointer"
        >
          <FileText className="size-8 stroke-1" />
          <span className="truncate text-sm flex-[0.65]">Resume</span>
        </Link>
      </div>

      {vacancy_status === 'pending' && (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4 mt-4"
          >
            <RichTextEditor
              name="message"
              label="Kandidatga yuboriladigan xabar"
              required
            />
            <RadioGroupField
              name="status"
              label="Kandidatning holati"
              data={vacancyStatusForm}
              required
            />
            <LoadingButton isLoading={isLoadingReject}>Yuborish</LoadingButton>
          </form>
        </Form>
      )}
    </>
  );
}
