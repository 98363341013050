import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form, FormDescription, FormMessage } from 'components/ui/form';
import { useCreateQuiz } from 'modules/quizzes/hooks/useCreateQuiz';
import { useEditQuiz } from 'modules/quizzes/hooks/useEditQuiz';
import { Quiz, QuizContentType, QuizType } from 'modules/quizzes/types';
import { useFileUpload } from 'modules/file-upload/hooks/useFileUpload';
import { FileField, SelectField, TextAreaField } from 'components/fields';
import { Button } from 'components/ui/button';
import LoadingButton from 'components/LoadingButton';
import { quizSelectType } from 'constants/index';
import QuizOptions from './QuizOptions';

const quizSchema = z.object({
  type: z.nativeEnum(QuizType, { required_error: 'Savol tipini tanlang' }),
  question: z.array(
    z.object({
      type: z.nativeEnum(QuizContentType),
      content: z.union([
        z.custom<File>(file => file instanceof File, {
          message: 'Rasm talab qilinadi',
        }),
        z.string().min(1, { message: 'Savolni kiriting' }),
      ]),
    })
  ),
  options: z
    .array(
      z.object({
        value: z.array(
          z.object({
            type: z.nativeEnum(QuizContentType),
            content: z.string().min(1, { message: 'Javobni kiriting' }),
          })
        ),
        is_correct: z.boolean(),
      })
    )
    .refine(data => data.some(option => option.is_correct), {
      message: "To'g'ri javobni belgilang",
      path: ['options'],
    }),
});

export type quizFormSchema = z.infer<typeof quizSchema>;

interface IProps {
  quiz?: Quiz;
  setSheetOpen: (state: boolean) => void;
}

export default function QuizForm({ quiz, setSheetOpen }: IProps) {
  const { lessonId } = useParams();
  const { triggerFileUpload, isPending } = useFileUpload();
  const { triggerQuizCreate, isPending: isQuizCreatePending } = useCreateQuiz({
    setSheetOpen,
  });
  const { triggerQuizEdit, isPending: isQuizEditPending } = useEditQuiz({
    id: quiz?._id,
    setSheetOpen,
  });

  const form = useForm<quizFormSchema>({
    resolver: zodResolver(quizSchema),
    defaultValues: quiz
      ? {
          type: quiz.type,
          question: quiz.question,
          options: quiz.options,
        }
      : {
          type: QuizType.SINGLE_SELECT,
          question: [{ type: QuizContentType.TEXT, content: '' }],
          options: [
            {
              value: [{ type: QuizContentType.TEXT, content: '' }],
              is_correct: false,
            },
            {
              value: [{ type: QuizContentType.TEXT, content: '' }],
              is_correct: false,
            },
            {
              value: [{ type: QuizContentType.TEXT, content: '' }],
              is_correct: false,
            },
          ],
        },
  });
  const {
    control,
    formState: { errors },
    getValues,
  } = form;
  const { fields: questionFields, append } = useFieldArray({
    name: 'question',
    control,
  });
  const quizType = getValues('type');

  async function onSubmit(formValues: quizFormSchema) {
    let values = formValues;
    const image = formValues.question.find(
      item => item.type === QuizContentType.PHOTO
    );

    if (image && image.content instanceof File) {
      const formData = new FormData();
      formData.append('file', image.content);

      const { data } = await triggerFileUpload(formData);
      values = {
        ...formValues,
        question: formValues.question.map(item =>
          item.type === QuizContentType.PHOTO
            ? { ...item, content: data.data.url }
            : item
        ),
      };
    }

    if (quiz) {
      triggerQuizEdit({ ...values, lesson: lessonId! });
    } else {
      triggerQuizCreate({ ...values, lesson: lessonId! });
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <SelectField
            name="type"
            data={quizSelectType}
            label="Savol tipini tanlang"
            required
          />
          {questionFields.map((field, index) => (
            <Fragment key={field.id}>
              {field.type === 'text' && (
                <TextAreaField
                  name={`question.${index}.content`}
                  label="Savol"
                  required
                />
              )}
              {field.type === 'photo' && (
                <FileField name={`question.${index}.content`} label="Rasm" />
              )}
            </Fragment>
          ))}
          <Button
            type="button"
            className="w-fit"
            onClick={() =>
              append({
                type: QuizContentType.PHOTO,
                content: '',
              })
            }
          >
            Rasm qo'shish
          </Button>
          <hr />
          <FormDescription className="mb-2 text-xs">
            {quizType === 'single_select'
              ? "Bitta to'g'ri javobni belgilang"
              : "Bir va undan ortiq to'g'ri javobni belgilang"}
          </FormDescription>
          <QuizOptions />

          {errors.options && (
            // @ts-ignore
            <FormMessage>{errors.options.options.message}</FormMessage>
          )}
        </div>
        {quiz ? (
          <LoadingButton isLoading={isQuizEditPending || isPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isQuizCreatePending || isPending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
