import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Category,
  CategoryCreate,
  CategoryType,
} from 'modules/categories/types';
import { useEditCategories } from 'modules/categories/hooks/useEditCategries';
import { useCreateCategory } from 'modules/categories/hooks/useCreateCategory';
import { Form } from 'components/ui/form';
import {
  FileField,
  SelectField,
  TextAreaField,
  TextField,
} from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import useFileUploader from 'hooks/useFileUploader';
import { categoryType } from 'constants/index';

const categoriesShcema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
  type: z.nativeEnum(CategoryType),
  banner_image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type categoriesFormShcema = z.infer<typeof categoriesShcema>;

interface IProps {
  category?: Category;
  setSheetOpen: (state: boolean) => void;
}
export default function CategoryForm({ category, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { triggerCategoryCreate, isPending: isCategoryCreatePending } =
    useCreateCategory({ setSheetOpen });
  const { triggerCategoryEdit, isPending: isCategoryEditPendig } =
    useEditCategories({ id: category?._id, setSheetOpen });

  const form = useForm<categoriesFormShcema>({
    resolver: zodResolver(categoriesShcema),
    defaultValues: category
      ? {
          name: category.name,
          description: category.description,
          banner_image: category.banner_image,
          type: category.type,
        }
      : {
          name: '',
          description: '',
          banner_image: undefined,
          type: CategoryType.PERSONAL_DEVELOPMENT,
        },
  });

  async function onSubmit(formValues: categoriesFormShcema) {
    const values = await uploadFile<CategoryCreate>(formValues, 'banner_image');

    if (category) {
      triggerCategoryEdit(values);
    } else {
      triggerCategoryCreate(values);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="name" label="Toifa nomi" required />
          <SelectField
            name="type"
            label="Toifa turi"
            data={categoryType}
            required
          />
          <TextAreaField name="description" label="Toifa tavsifi" required />
          <FileField name="banner_image" label="Toifa rasmi" required />
        </div>
        {category ? (
          <LoadingButton isLoading={isCategoryEditPendig}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isCategoryCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
