import { ColumnDef } from '@tanstack/react-table';
import { Teacher } from 'modules/teachers/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { normalizeImgUrl } from 'utils/normalizeImgUrl';

interface IProps {
  getRowData: (course: Teacher) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}
export const createTeacherColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Teacher>[] =>[
  {
    accessorKey: 'image',
    header: 'Rasm',
    cell:({row})=>(
        <div className="flex items-center gap-1">
         <div className="w-2 h-2 sm:w-4 sm:h-4 md:w-8 md:h-8 overflow-hidden rounded-md">
            <img className="w-full h-full object-cover" src={`${normalizeImgUrl(row.getValue('image'))}`} alt="img" />
         </div>
        </div>
        )
       
  },
  {
    accessorKey: 'full_name',
    header: "O'qituvchi ismi",
  },
  {
    accessorKey: 'bio',
    header: "O'qituvchini BIO"
  },
 
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
