import { DataTable } from 'components/DataTable';
import { useTeachersList } from 'modules/teachers/hooks/useTeachersList';
import Loader from 'components/Loader';
import { TableActions } from 'components/TableActions';
import { Pagination } from 'components/Pagination';
import { useState } from 'react';
import { Teacher } from 'modules/teachers/types';
import { useDeleteTeacher } from 'modules/teachers/hooks/useTeacherDelete';
import { createTeacherColumns } from './Columns';
import { TeacherFroms } from './TeacherForm';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';

const Teachers = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [teacher, setTeacher] = useState<Teacher>();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: teachersList,
    paginationInfo,
    isLoading,
  } = useTeachersList(currentPage);
  const { triggerTeacherDelete } = useDeleteTeacher(teacher?._id!);

  const getRowData = (teachers: Teacher) => {
    setTeacher(teachers);
  };

  const columns = createTeacherColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="O'qtuvchi qo'shish"
        sheetTitle="Yangi O'qtuvchi qo'shish."
        TableForm={TeacherFroms}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable
            columns={columns}
            data={teachersList}
            navigateTable={false}
          />
          <Pagination
            className="justify-end mt-3"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginationInfo={paginationInfo}
          />
        </>
      )}

      <Sheet
        sheetTitle="Bo'limni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <TeacherFroms teachers={teacher} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerTeacherDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Teachers;
