import { ColumnDef } from '@tanstack/react-table';
import { Category } from 'modules/categories/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { normalizeImgUrl } from 'utils/normalizeImgUrl';

interface IProps {
  getRowData: (course: Category) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createCategoryColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Category>[] => [
  {
    accessorKey: 'banner_image',
    header: 'Rasm',
    cell: ({ row }) => (
      <div className="flex items-center gap-1">
        <div className="w-2 h-2 sm:w-4 sm:h-4 md:w-8 md:h-8 overflow-hidden rounded-md">
          <img
            className="w-full h-full object-cover"
            src={`${normalizeImgUrl(row.getValue('banner_image'))}`}
            alt="Banner"
          />
        </div>
      </div>
    ),
  },
  {
    accessorKey: 'name',
    header: 'Toifa Nomi',
  },
  {
    accessorKey: 'description',
    header: 'Tavsifi',
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
