import http from 'services/api';
import { NewsCreate, NewsEdit } from './types';

export const GetNewsList = async (currentPage: number) => {
  return await http.get(`/news?page=${currentPage}`);
};

export const CreateNews = async (values: NewsCreate) => {
  return await http.post(`/news/`, values);
};

export const EditNews = async ({ values, id }: NewsEdit) => {
  return await http.put(`/news/${id}`, values);
};
export const DeleteNews = async (id: string) => {
  return await http.delete(`/news/${id}`);
};
