import { Lesson } from './types';
export const getLesson = (item?: Lesson) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
    description: item?.description ?? '',
    youtube_link: item?.youtube_link ?? '',
    download_link: item?.download_link ?? '',
    banner_image: item?.banner_image ?? '',
    duration: item?.duration ?? 0,
    order: item?.order ?? 0,
  };
};

export const getLessonsList = (data?: Lesson[]) => {
  return data?.length
    ? data.map(item => {
        return getLesson(item);
      })
    : [];
};
