import { useToast } from "components/ui/use-toast";
import { EditCategories } from "../api";
import { CategoryCreate } from "../types";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";


interface IHook {
    id?: string;
    setSheetOpen: (state: boolean) => void;
}


export const useEditCategories = ({ id = '', setSheetOpen }: IHook) => {
    const { toast } = useToast();
  
    const { mutate, isPending, isSuccess, isError } = useMutation({
      mutationFn: (values: CategoryCreate) =>EditCategories({values, id}),
      onSuccess: () => {
        toast({
          variant: 'success',
          title: 'Muvaffaqiyat!',
          description: 'Toifa muvaffaqiyatli tahrirlandi.',
        });
        queryClient.invalidateQueries({ queryKey: ['categories_list'] });
        setSheetOpen(false);
      },
      onError: (error: any) => showErrorToast(error),
    });
  
    return {
      triggerCategoryEdit: mutate,
      isPending,
      isSuccess,
      isError,
    };
  };

