import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { News, NewsCreate } from 'modules/news/types';
import useFileUploader from 'hooks/useFileUploader';
import { useCreateNews } from 'modules/news/hooks/useCreateNews';
import { useEditNews } from 'modules/news/hooks/useEditNews';
import { Form } from 'components/ui/form';
import { FileField, TextField, RichTextEditor } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

const newsSchema = z.object({
  title: z.string().min(3),
  content: z.string().min(3),
  short_description: z.string().min(3),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type newsFormSchema = z.infer<typeof newsSchema>;

interface IProps {
  news?: News;
  setSheetOpen: (state: boolean) => void;
}

export default function NewsForm({ news, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { triggerNewsCreate, isPending: isNewsCreatePending } = useCreateNews({
    setSheetOpen,
  });
  const { triggerNewsEdit, isPending: isNewsEditPending } = useEditNews({
    id: news?._id,
    setSheetOpen,
  });

  const form = useForm<newsFormSchema>({
    resolver: zodResolver(newsSchema),
    defaultValues: news
      ? {
          title: news.title,
          short_description: news.short_description,
          content: news.content,
          image: news.image,
        }
      : {
          title: '',
          short_description: '',
          content: '',
          image: undefined,
        },
  });

  async function onSubmit(formValues: newsFormSchema) {
    const values = await uploadFile<NewsCreate>(formValues, 'image');

    if (news) {
      triggerNewsEdit(values);
    } else {
      triggerNewsCreate(values);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="title" label="Yangilik nomi" required />
          <TextField
            name="short_description"
            label="Yangilik qisqacha tavsifi"
            required
          />

          <RichTextEditor name="content" label="Yangilik tavsifi" required />
          <FileField name="image" label="Yangilik rasmi" required />
        </div>
        {news ? (
          <LoadingButton isLoading={isNewsEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isNewsCreatePending}>Saqlash</LoadingButton>
        )}
      </form>
    </Form>
  );
}
