import { useState } from 'react';
import { useNotificationsList } from 'modules/notifications/hooks/useNotificationsList';
import { DataTable } from 'components/DataTable';
import { createNotificationColumns } from './Columns';
import Loader from 'components/Loader';
import { TableActions } from 'components/TableActions';
import { Notification } from 'modules/notifications/types';
import NotificationForm from './NotificationForm';
import { useDeleteNotification } from 'modules/notifications/hooks/useDeleteNotification';
import { AlertDialog } from 'components/AlertDialog';
import { Sheet } from 'components/Sheet';

const Notifications = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification>();
  const { data: notificationsList, isLoading } = useNotificationsList();
  const { triggerNotificationDelete } = useDeleteNotification(
    notifications?._id!
  );
  const getRowData = (notifications: Notification) => {
    setNotifications(notifications);
  };

  const columns = createNotificationColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="E'lon qo'shish"
        sheetTitle="Yangi E'lon qo'shish."
        TableForm={NotificationForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable
          columns={columns}
          data={notificationsList}
          navigateTable={false}
        />
      )}

      <Sheet
        sheetTitle="Bo'limni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <NotificationForm
          notification={notifications}
          setSheetOpen={setSheetOpen}
        />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerNotificationDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Notifications;
