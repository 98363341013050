import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { CreateNews } from "../api";
import { NewsCreate } from "../types";

interface IHook {
    setSheetOpen: (state: boolean) => void;
  }
  
  export const useCreateNews = ({ setSheetOpen }: IHook) => {
    const { toast } = useToast();
  
    const { mutate, isPending, isSuccess, isError } = useMutation({
      mutationFn: (values: NewsCreate) => CreateNews(values),
      onSuccess: () => {
        toast({
          variant: 'success',
          title: 'Muvaffaqiyat!',
          description: 'Yangilik muvaffaqiyatli yaratildi.',
        });
        queryClient.invalidateQueries({ queryKey: ['news_list'] });
        setSheetOpen(false);
      },
      onError: (error: any) => showErrorToast(error),
    });
  
    return {
      triggerNewsCreate: mutate,
      isPending,
      isSuccess,
      isError,
    };
  };