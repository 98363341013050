import { Vacancy } from 'modules/vacancy/types';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';

interface IProps {
  getRowData: (vacancy: Vacancy) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createVacancyColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Vacancy>[] => [
  {
    accessorKey: 'title',
    header: 'Vakansiya nomi',
  },
  {
    accessorKey: 'company',
    header: 'Kompaniya nomi',
  },
  {
    accessorKey: 'address',
    header: 'Manzili',
  },
  {
    accessorKey: 'salary',
    header: 'Oyligi',
  },
  {
    accessorKey: 'submit_count',
    size: 50,
    header: 'Resume yuborganlar soni',
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
