import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Vacancy } from 'modules/vacancy/types';
import { useCreateVacancy } from 'modules/vacancy/hook/useCreateVacancy';
import { useEditVacncy } from 'modules/vacancy/hook/useEditVacancy';
import { Form } from 'components/ui/form';
import { DataPicker, RichTextEditor, TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

const vacancyShcema = z.object({
  title: z.string().min(1, { message: 'Vakansiya nomini kiriting' }),
  description: z.string().min(1, { message: 'Vakansiya tarifini kiriting' }),
  company: z.string().min(1, { message: 'Kompaniya nomini kiriting' }),
  deadline: z.date({
    required_error: 'Vakansiyanig tugash muddatini kiriting',
  }),
  salary: z.string().min(1, { message: 'Oylik maoshini kiriting' }),
  address: z.string().min(1, { message: 'Manzilni kiriting' }),
});

type vacancyFormShcema = z.infer<typeof vacancyShcema>;

interface IProps {
  vacancy?: Vacancy;
  setSheetOpen: (state: boolean) => void;
}

export default function VacancyForm({ vacancy, setSheetOpen }: IProps) {
  const { triggerVacancyCreate, isPending: isVacancyCreatePending } =
    useCreateVacancy({ setSheetOpen });
  const { triggerVacancyEdit, isPending: isVacancyEditPending } = useEditVacncy(
    {
      id: vacancy?._id,
      setSheetOpen,
    }
  );

  const form = useForm<vacancyFormShcema>({
    resolver: zodResolver(vacancyShcema),
    defaultValues: vacancy
      ? {
          title: vacancy.title,
          description: vacancy.description,
          company: vacancy.company,
          deadline:
            typeof vacancy.deadline === 'string'
              ? new Date(vacancy.deadline)
              : vacancy.deadline,
          salary: vacancy.salary,
          address: vacancy.address,
        }
      : {
          title: '',
          description: '',
          company: '',
          deadline: undefined,
          salary: '',
          address: '',
        },
  });

  const onSubmit = async (formValues: vacancyFormShcema) => {
    if (vacancy) {
      triggerVacancyEdit(formValues);
    } else {
      triggerVacancyCreate(formValues);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="title" label="Vakansiya nomi" required />
          <TextField name="company" label="Kompaniya nomi" required />
          <RichTextEditor
            name="description"
            label="Vakansiya tavsifi"
            required
          />
          <TextField name="salary" label="Oylik maoshi" required />
          <TextField name="address" label="Manzil" required />
          <DataPicker name="deadline" label="Tugash muddati" />
        </div>
        {vacancy ? (
          <LoadingButton isLoading={isVacancyEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isVacancyCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
