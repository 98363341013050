import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResumeList } from 'modules/resume/hooks/useResumesList';

import { Sheet } from 'components/Sheet';
import ResumeForm from './ResumeForm';
import { SubmittedResume } from 'modules/resume/type';
import { DataTable } from 'components/DataTable';
import Loader from 'components/Loader';
import { createResumeColumns } from './Columns';

const ResumePage = () => {
  const { vacancyId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { data: resumeList, isLoading } = useResumeList(vacancyId!);
  const [resume, setResume] = useState<SubmittedResume>();

  const getRowData = (resume: SubmittedResume) => {
    setResume(resume);
  };

  const columns = createResumeColumns({
    getRowData,
    setIsOpen,
  });

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={resumeList} />
      )}
      <Sheet
        sheetTitle="Resume tavsifi"
        isOpen={isOpen}
        setSheetOpen={setIsOpen}
      >
        <ResumeForm resume={resume!} setIsOpen={setIsOpen} />
      </Sheet>
    </div>
  );
};

export default ResumePage;
