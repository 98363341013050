import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { DeleteNotifications } from "../api";

export const useDeleteNotification = (id: string) => {
    const { toast } = useToast();
  
    const { mutate, isSuccess, isError } = useMutation({
      mutationFn: () => DeleteNotifications(id),
      onSuccess: () => {
        toast({
          variant: 'success',
          title: "Muvaffaqiyatli o'chirildi!",
        });
        queryClient.invalidateQueries({ queryKey: ['notifications_list'] });
      },
      onError: (error: any) => showErrorToast(error),
    });
  
    return { triggerNotificationDelete: mutate, isSuccess, isError };
  };