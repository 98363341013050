import { UsersCount } from './types';
export const getUsersCount = (item?: UsersCount) => {
  return {
    _id: item?._id ?? '',
    profiles: item?.profiles ?? 0,
    submissions: item?.submissions ?? 0,
  };
};

export const getUsersCountList = (data?: UsersCount[]) => {
  return data?.length
    ? data.map(item => {
        return getUsersCount(item);
      })
    : [];
};
