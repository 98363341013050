import http from 'services/api';
import { CategoryCreate, CategoryEdit } from './types';

export const GetCategoriesList = async () => {
  return await http.get(`/categories/`);
};

export const CreateCategories = async (values: CategoryCreate) => {
  return await http.post(`/categories/`, values);
};

export const EditCategories = async ({ values, id }: CategoryEdit) => {
  return await http.put(`/categories/${id}`, values);
};
export const DeleteCategory = async (id: string) => {
  return await http.delete(`categories/${id}`);
};
