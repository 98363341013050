import { ColumnDef } from '@tanstack/react-table';
import { Question, Quiz } from 'modules/quizzes/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { quizSelectType } from 'constants/index';

interface IProps {
  getRowData: (quiz: Quiz) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createQuizColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Quiz>[] => [
  {
    accessorKey: 'question',
    header: 'Quiz savoli',
    cell: ({ row }) => {
      const question: Question[] = row.getValue('question');
      return question.map(
        item =>
          item.type === 'text' && <p key={item._id}>{item.content as string}</p>
      );
    },
  },
  {
    accessorKey: 'type',
    header: 'Savol tipi',
    cell: ({ row }) => {
      const type = quizSelectType.find(
        item => item.type === row.getValue('type')
      );
      return <p>{type?.name}</p>;
    },
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
