import { ColumnDef } from '@tanstack/react-table';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { vacancyStatus } from 'constants/index';

import { SubmittedResume } from 'modules/resume/type';

interface IProps {
  setIsOpen: (state: boolean) => void;
  getRowData: (resume: SubmittedResume) => void;
}

export const createResumeColumns = ({
  setIsOpen,
  getRowData,
}: IProps): ColumnDef<SubmittedResume>[] => [
  {
    accessorKey: 'resumes_info.full_name',
    header: "To'liq ismi",
  },
  {
    accessorKey: 'resumes_info.job_title',
    header: 'Kasbi',
  },
  {
    accessorKey: 'resumes_info.exprience',
    header: 'Tajriba',
  },
  {
    accessorKey: 'vacancy_status',
    header: 'Holati',
    cell: ({ row }) => {
      const status = vacancyStatus.find(
        status => status.value === row.getValue('vacancy_status')
      );
      return <p>{status?.title}</p>;
    },
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setSheetOpen={setIsOpen}
      />
    ),
  },
];
