import { useMutation } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { DeleteVacancy } from "../api";



export const useDeleteVacancy = (id: string) => {
    const { toast } = useToast();
  
    const { mutate, isSuccess, isError } = useMutation({
      mutationFn: () => DeleteVacancy(id),
      onSuccess: () => {
        toast({
          variant: 'success',
          title: "Muvaffaqiyatli o'chirildi!",
        });
        queryClient.invalidateQueries({ queryKey: ['vacancy_list'] });
      },
      onError: (error: any) => showErrorToast(error),
    });
  
    return { triggerVacancyDelete: mutate, isSuccess, isError };
  };