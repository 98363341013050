import http from 'services/api';
import { ResumeInput } from './type';

export const GetResumeList = async (vacancyId: string) => {
  return await http.get(`vacancy/${vacancyId}/submitted-resume`);
};

export const ReplyResume = async (vacancyId: string, values: ResumeInput) => {
  return await http.post(`vacancy/${vacancyId}/reply-vacancy`, values);
};
