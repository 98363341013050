import http from 'services/api';
import { NotificationCreate, NotificationEdit } from './types';
export const GetNotificationsList = async () => {
  return await http.get(`/notification/all`);
};
export const  CreateNotifications= async (values: NotificationCreate)=>{
  return await http.post(`/notification/send`, values)
}
export const EditNotifications = async ({id, values}:NotificationEdit)=>{
  return await http.put(`/notification/${id}`, values)

}

export const DeleteNotifications = async(id:string)=>{
  return await http.delete(`/notification/${id}`)
}