import { useQuery } from '@tanstack/react-query';
import { getResumeList } from '../adapters';
import { GetResumeList } from '../api';
import { get } from 'lodash';

export const useResumeList = (vacancyId: string) => {
  const initialData = {
    data: getResumeList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['resumes_list', vacancyId],
    queryFn: () => GetResumeList(vacancyId),
    select: data => ({
      data: getResumeList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
