import { Teacher } from './types';
export const getTeacher = (item?: Teacher) => {
  return {
    _id: item?._id ?? '',
    full_name: item?.full_name ?? '',
    bio: item?.bio ?? '',
    social_links: item?.social_links?.length ? item.social_links : [],
    image: item?.image ?? '',
  };
};

export const getTeachersList = (data?: Teacher[]) => {
  return data?.length
    ? data.map(item => {
        return getTeacher(item);
      })
    : [];
};
