import { ArrowDown, ArrowUp } from "lucide-react";
import { Column } from "@tanstack/react-table";
import { cn } from "utils/styleUtils";

interface DataTableColumnHeaderProps<TData, TValue> {
  column: Column<TData, TValue>;
  title: string;
  className?: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const isSorted =
    column.getIsSorted() === "desc" || column.getIsSorted() === false;

  return (
    <div className={cn("flex items-center", className)}>
      <span>{title}</span>
      <div>
        {isSorted ? (
          <ArrowDown
            className="size-4 select-none"
            onClick={() => column.toggleSorting(false)}
          />
        ) : (
          <ArrowUp
            className="size-4 select-none"
            onClick={() => column.toggleSorting(true)}
          />
        )}
      </div>
    </div>
  );
}
