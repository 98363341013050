import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { GetUsersCountByArea } from "../api";
import { getUsersCountList } from "../adapter";

export const useUsersCountByDistrict = (
  regionId?: string,
  districtId?: string
) => {
  const initialData = {
    data: getUsersCountList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["users_count_by_district", regionId, districtId],
    queryFn: () => GetUsersCountByArea(regionId, districtId),
    select: (data) => ({
      data: getUsersCountList(get(data, "data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
