import { Link, NavLink } from "react-router-dom";
import {
  BookAudio,
  Newspaper,
  Users,
  Bell,
  BriefcaseBusiness,
  LayoutList,
  MessageSquareText,
  CircleFadingPlus,
  BarChart3,
} from "lucide-react";
import logo from "logo.svg";
import { Button } from "components/ui/button";
import { cn } from "utils/styleUtils";

interface IProps {
  isSideNavOpen: boolean;
}

const SideNav = ({ isSideNavOpen }: IProps) => {
  const menuItems = [
    {
      title: "Statistics",
      icon: BarChart3,
      link: "/",
    },
    {
      title: "Courses",
      icon: BookAudio,
      link: "/courses",
    },
    {
      title: "News",
      icon: Newspaper,
      link: "/news",
    },
    {
      title: "Vacancies",
      icon: BriefcaseBusiness,
      link: "/vacancies",
    },
    {
      title: "Categories",
      icon: LayoutList,
      link: "/categories",
    },
    {
      title: "Teachers",
      icon: Users,
      link: "/teachers",
    },
    {
      title: "Stories",
      icon: CircleFadingPlus,
      link: "/stories",
    },
    {
      title: "Comments",
      icon: MessageSquareText,
      link: "/comments",
    },
    {
      title: "Notifications",
      icon: Bell,
      link: "/notifications",
    },
  ];

  return (
    <aside
      className={cn(
        { "w-full": isSideNavOpen },
        "sticky top-0 max-w-72 flex flex-col border-solid border-r-2 h-screen"
      )}
    >
      <header className="z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-2">
        <Link
          to="/"
          className={`flex h-10 items-center gap-2 px-4 dark:text-white ${
            isSideNavOpen ? "justify-start" : "justify-center"
          }`}
        >
          <img src={logo} alt="logo" className="size-5" />
          {isSideNavOpen && (
            <h1 className="font-semibold">Qizlar Akademiyasi</h1>
          )}
        </Link>
      </header>
      <div className="flex flex-col gap-1 p-2">
        {menuItems.map((item, index) => (
          <NavLink
            to={item.link}
            key={index}
            className={({ isActive }) =>
              cn({ "bg-secondary": isActive }, "dark:text-white rounded block")
            }
          >
            <Button
              variant="ghost"
              className={`w-full ${
                isSideNavOpen ? "justify-start" : "justify-center"
              }`}
            >
              <item.icon className="size-5 stroke-[1.3px]" />
              {isSideNavOpen && <span className="ml-3">{item.title}</span>}
            </Button>
          </NavLink>
        ))}
      </div>
    </aside>
  );
};

export default SideNav;
