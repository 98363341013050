import React, { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Form, FormField, FormItem } from 'components/ui/form';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { useSignIn } from 'modules/auth/hooks/useSignIn';
import { Eye, EyeOff } from 'lucide-react';

const AuthForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(true);

  const { triggerSignIn } = useSignIn();
  const formSchema = z.object({
    email: z.string().min(3),
    password: z.string().min(3),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    triggerSignIn(values);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid w-full items-center gap-4"
      >
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <Label htmlFor="username">Username</Label>
              <Input id="username" placeholder="Username" {...field} />
            </FormItem>
          )}
        />
        <FormField
          name="password"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <Label htmlFor="password">Password</Label>
              <div className="relative">
                <Input
                  id="password"
                  placeholder="Password"
                  type={passwordVisible ? 'password' : 'text'}
                  {...field}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? (
                    <Eye className="shadcn-icon size-5" />
                  ) : (
                    <EyeOff className="shadcn-icon size-5" />
                  )}
                </div>
              </div>
            </FormItem>
          )}
        />
        <Button type="submit">Login</Button>
      </form>
    </Form>
  );
};

export default AuthForm;
