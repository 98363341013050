import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { GetUsersCountByCountry } from "../api";
import { getUsersCountList } from "../adapter";

export const useUsersCountByCountry = () => {
  const initialData = {
    data: getUsersCountList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ["users_count_by_country"],
    queryFn: () => GetUsersCountByCountry(),
    select: (data) => ({
      data: getUsersCountList(get(data, "data.data")),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
