import { useQuery } from "@tanstack/react-query"
import { getCommentsList } from "../adapters"
import { GetCommentsList } from "../api"
import { get } from "react-hook-form"

export const useCommentsList = (courseId: string)=>{
    const initialData ={
        data:getCommentsList()
    } 

    const {data = initialData, ...args } = useQuery({
        queryKey: ['comments_list',courseId],
        queryFn:()=> GetCommentsList(courseId),
        select: data =>({
            data: getCommentsList(get(data, 'data.data')),
        }),

    });
    return {
        ...data,
        ...args,
      };
}
