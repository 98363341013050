import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Story } from 'modules/stories/types';

import { useCreateStory } from 'modules/stories/hooks/useCreateStory';
import { useEditStory } from 'modules/stories/hooks/useEditStory';
import { Form } from 'components/ui/form';
import { TextField } from 'components/fields';
import LoadingButton from 'components/LoadingButton';

const storySchema = z.object({
  title: z.string().min(3, { message: 'Hikoya nomi talab qilinadi' }),
  link: z.string().url({ message: "Noto'g'ri link kiritilgan" }),
});

type storyFormSchema = z.infer<typeof storySchema>;

interface IProps {
  story?: Story;
  setSheetOpen: (state: boolean) => void;
}

export default function StoryForm({ story, setSheetOpen }: IProps) {
  const { triggerStoryCreate, isPending: isStoryCreatePending } =
    useCreateStory({ setSheetOpen });
  const { triggerStoryEdit, isPending: isStoryEditPending } = useEditStory({
    id: story?._id,
    setSheetOpen,
  });

  const form = useForm<storyFormSchema>({
    resolver: zodResolver(storySchema),
    defaultValues: story
      ? {
          title: story.title,
          link: story.link,
        }
      : {
          title: '',
          link: '',
        },
  });

  async function onSubmit(formValues: storyFormSchema) {
    if (story) {
      triggerStoryEdit(formValues);
    } else {
      triggerStoryCreate(formValues);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="title" label="Hikoya nomi" required />
          <TextField name="link" label="Hikoya havolasi" required />
        </div>
        {story ? (
          <LoadingButton isLoading={isStoryEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isStoryCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
