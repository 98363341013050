import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getStoriesList } from '../adapters';
import { GetStoriesList } from '../api';

export const useStoriesList = () => {
  const initialData = {
    data: getStoriesList(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['stories_list'],
    queryFn: () => GetStoriesList(),
    select: data => ({
      data: getStoriesList(get(data, 'data.data')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
