import { useState } from 'react';
import { Story } from 'modules/stories/types';
import { useStoriesList } from 'modules/stories/hooks/useStoriesList';
import { useDeleteStory } from 'modules/stories/hooks/useDeleteStory';
import { DataTable } from 'components/DataTable';
import Loader from 'components/Loader';
import { TableActions } from 'components/TableActions';
import { AlertDialog } from 'components/AlertDialog';
import { Sheet } from 'components/Sheet';
import StoryForm from './StoryForm';
import { createStoryColumns } from './Columns';

const Stories = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [story, setStory] = useState<Story>();

  const { data: storiesList, isLoading } = useStoriesList();
  const { triggerStoryDelete } = useDeleteStory(story?._id!);

  const getRowData = (story: Story) => {
    setStory(story);
  };

  const columns = createStoryColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Hikoya qo'shish"
        sheetTitle="Yangi hikoya qo'shish."
        TableForm={StoryForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={storiesList} />
      )}

      <Sheet
        sheetTitle="Hikoyani tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <StoryForm story={story} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerStoryDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Stories;
