import NewUsersChart from "components/charts/NewUsers";
import Overview from "./Overview";
import StatisticsByArea from "./StatisticsByArea";

const Home = () => {
  return (
    <div className="flex flex-col gap-4">
      <Overview />
      <StatisticsByArea />
      <NewUsersChart />
    </div>
  );
};

export default Home;
