import http from 'services/api';
import { StoryEditBody, StoryInput } from './types';

export const GetStoriesList = async () => {
  return await http.get(`/story/`);
};

export const CreateStory = async (values: StoryInput) => {
  return await http.post(`/story/`, values);
};

export const EditStory = async ({ values, id }: StoryEditBody) => {
  return await http.put(`/story/${id}`, values);
};

export const DeleteStory = async (id: string) => {
  return await http.delete(`/story/${id}`);
};
