import { useMutation } from "@tanstack/react-query";

import { VacancyBody } from "../types";
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { useToast } from 'components/ui/use-toast';
import { CreateVacancy } from "../api";


interface IHook {
    setSheetOpen: (state: boolean) => void;
}

export const useCreateVacancy = ({setSheetOpen}: IHook) =>{
    const { toast } = useToast();

    const { mutate, isPending, isSuccess, isError } = useMutation({
      mutationFn: (values: VacancyBody) => CreateVacancy(values),
      onSuccess: () => {
        toast({
          variant: 'success',
          title: 'Muvaffaqiyat!',
          description: 'Vakansiya muvaffaqiyatli yaratildi.',
        });
        queryClient.invalidateQueries({ queryKey: ['vacancy_list'] });
        setSheetOpen(false);
      },
      onError: (error: any) => showErrorToast(error),
    });
  
    return {
      triggerVacancyCreate: mutate,
      isPending,
      isSuccess,
      isError,
    };

}