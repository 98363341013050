import { useMutation } from "@tanstack/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { GetNeighborhoodStatisticsExcel } from "../api";
import downloadFile from "utils/downloadFile";

export const useNeighborhoodStatisticsExcel = (
  region: string,
  district: string
) => {
  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: () => GetNeighborhoodStatisticsExcel(region, district),
    onSuccess: ({ data }) =>
      downloadFile({ data, fileName: "mahalla-statistika" }),
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerNeighborhoodExcel: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
