import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getVacancyList } from '../adapter';
import { GetVacancyList } from '../api';
import { getPagination } from 'modules/adapter';

export const useVacancyList = (currentPage: number) => {
  const initialData = {
    data: getVacancyList(),
    paginationInfo: getPagination(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['vacancy_list'],
    queryFn: () => GetVacancyList(currentPage),
    select: data => ({
      data: getVacancyList(get(data, 'data.data')),
      paginationInfo: getPagination(get(data, 'data.pagination_info')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
