import { useMutation } from "@tanstack/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { GetRegionStatisticsExcel } from "../api";
import downloadFile from "utils/downloadFile";

export const useRegionStatisticsExcel = () => {
  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: () => GetRegionStatisticsExcel(),
    onSuccess: ({ data }) =>
      downloadFile({ data, fileName: "viloyat-statistika" }),
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerRegionExcel: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
