import { DataTable } from 'components/DataTable';
import Loader from 'components/Loader';
import { useCoursesList } from 'modules/courses/hooks/useCoursesList';
import { createCommentColumns } from './Column';

const Comments = () => {
  const { data: coursesList, isLoading } = useCoursesList();
  const columns = createCommentColumns();
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable columns={columns} data={coursesList} navigateTable />
      )}
    </div>
  );
};
export default Comments;
