import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from 'components/ui/form';
import {
  FileField,
  TextAreaField,
  TextField,
  MultiTextField,
} from 'components/fields';
import useFileUploader from 'hooks/useFileUploader';
import { Teacher, TeacherCreate } from 'modules/teachers/types';
import { useTeacherCreate } from 'modules/teachers/hooks/useTeacherCreate';
import { useTeacherEdit } from 'modules/teachers/hooks/useTeacherEdit';
import LoadingButton from 'components/LoadingButton';

const teacherSchema = z.object({
  full_name: z.string().min(3),
  bio: z.string().min(3),
  social_links: z.array(z.string().url({ message: "To'g'ri link kiriting" })),
  image: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type teacherFormSchema = z.infer<typeof teacherSchema>;

interface IProps {
  teachers?: Teacher;
  setSheetOpen: (state: boolean) => void;
}

export function TeacherFroms({ teachers, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { triggerTeacherCreate, isPending: isTeacherCreatePending } =
    useTeacherCreate({ setSheetOpen });
  const { triggerTeacherEdit, isPending: isTeacherEditPending } =
    useTeacherEdit({ id: teachers?._id, setSheetOpen });

  const form = useForm<teacherFormSchema>({
    resolver: zodResolver(teacherSchema),
    defaultValues: teachers
      ? {
          full_name: teachers.full_name,
          bio: teachers.bio,
          social_links: teachers.social_links,
          image: teachers.image,
        }
      : {
          full_name: '',
          bio: '',
          social_links: [],
          image: undefined,
        },
  });

  async function onSubmit(formValues: teacherFormSchema) {
    const values = await uploadFile<TeacherCreate>(formValues, 'image');

    if (teachers) {
      triggerTeacherEdit(values);
    } else {
      triggerTeacherCreate(values);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField
            name="full_name"
            label="O'qtuvchini To'liq Ismi"
            required
          />
          <TextAreaField name="bio" label="O'qtuvchini BIO" required />
          <MultiTextField
            name="social_links"
            label="Linklar"
            placeholder="Link qo'shish"
          />
          <FileField name="image" label="O'qtuvchini rasmi" required />
        </div>
        {teachers ? (
          <LoadingButton isLoading={isTeacherEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isTeacherCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
