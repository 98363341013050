import http from 'services/api';
import { VacancyBody, VacancyEdit } from './types';

export const GetVacancyList = async (currentPage: number) => {
  return await http.get(`/vacancy?page=${currentPage}`);
};

export const CreateVacancy = async (values: VacancyBody) => {
  return await http.post('/vacancy/', values);
};

export const EditeVacancy = async ({ values, id }: VacancyEdit) => {
  return await http.put(`/vacancy/${id}/`, values);
};

export const DeleteVacancy = async (id: string) => {
  return await http.delete(`/vacancy/${id}`);
};
