import { Category, CategoryType } from './types';

export const getCategories = (item?: Category) => {
  return {
    _id: item?._id ?? '',
    name: item?.name ?? '',
    description: item?.description ?? '',
    banner_image: item?.banner_image ?? '',
    type: item?.type ?? CategoryType.PERSONAL_DEVELOPMENT,
  };
};

export const getCategoriesList = (data?: Category[]) => {
  return data?.length
    ? data.map(item => {
        return getCategories(item);
      })
    : [];
};
