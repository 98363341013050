import { Notification } from './types';
export const getNotification = (item?: Notification) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    body: item?.body ?? '',
    image: item?.image ?? '',
    date: item?.date ?? '',
  };
};

export const getNotificationsList = (data?: Notification[]) => {
  return data?.length
    ? data.map(item => {
        return getNotification(item);
      })
    : [];
};
