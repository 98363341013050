import { Comments } from "./types";

export const getComments = (item: Comments)=>{
    return{
        id: item?._id ?? '',
        is_hide: item?.is_hide ?? false,
        comment_body: item?.comment_body ?? '',
        user_profile:  item?.user_profile ?? {
            first_name: "",
            last_name: "",
            image: "",
        },
        created_at: item?.created_at ?? new Date(),
        }
}

export const getCommentsList = (data?: Comments[])=>{
    return data?.length
    ? data.map(item => {
       return getComments(item);
      })
     : [];

}