import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Course, CourseInput } from 'modules/courses/types';

import useFileUploader from 'hooks/useFileUploader';
import { useCreateCourse } from 'modules/courses/hooks/useCreateCourse';
import { useEditCourse } from 'modules/courses/hooks/useEditCourse';
import { Form } from 'components/ui/form';
import {
  FileField,
  RichTextEditor,
  SelectField,
  TextField,
} from 'components/fields';
import LoadingButton from 'components/LoadingButton';
import { useTeachersList } from 'modules/teachers/hooks/useTeachersList';
import { useCategoriesList } from 'modules/categories/hooks/useCategriesList';

const courseSchema = z.object({
  name: z.string().min(3),
  short_description: z.string().min(3),
  description: z.string().min(3),
  category: z.string(),
  teacher: z.string(),
  icon: z.union([
    z.custom<File>(file => file instanceof File, {
      message: 'Rasm talab qilinadi',
    }),
    z.string().min(1, { message: 'Rasm talab qilinadi' }),
  ]),
});

type courseFormSchema = z.infer<typeof courseSchema>;

interface IProps {
  course?: Course;
  setSheetOpen: (state: boolean) => void;
}

export default function CourseForm({ course, setSheetOpen }: IProps) {
  const { uploadFile } = useFileUploader();
  const { triggerCourseCreate, isPending: isCourseCreatePending } =
    useCreateCourse({ setSheetOpen });
  const { triggerCourseEdit, isPending: isCourseEditPending } = useEditCourse({
    id: course?._id,
    setSheetOpen,
  });
  const { data: teachersList } = useTeachersList();
  const { data: categoriesList } = useCategoriesList();

  const form = useForm<courseFormSchema>({
    resolver: zodResolver(courseSchema),
    defaultValues: course
      ? {
          name: course.name,
          short_description: course.short_description,
          description: course.description,
          teacher: course.teacher?._id,
          category: course.category?._id,
          icon: course.icon,
        }
      : {
          name: '',
          short_description: '',
          description: '',
          teacher: '',
          category: '',
          icon: undefined,
        },
  });

  console.log(form.getValues());

  async function onSubmit(formValues: courseFormSchema) {
    const values = await uploadFile<CourseInput>(formValues, 'icon');

    if (course) {
      triggerCourseEdit(values);
    } else {
      triggerCourseCreate(values);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        <div className="flex gap-4 flex-col my-4">
          <TextField name="name" label="Kurs nomi" required />
          <TextField
            name="short_description"
            label="Kurs qisqacha tavsifi"
            required
          />
          <RichTextEditor name="description" label="Kurs tavsifi" required />
          <SelectField
            name="category"
            label="Toifalarni tanglang"
            placeholder="Toifani tanlash"
            data={categoriesList.map(category => ({
              name: category.name,
              type: category._id,
            }))}
          />
          <SelectField
            name="teacher"
            label="O'qtuvchi"
            placeholder="O'qtuvchini tanlash"
            data={teachersList.map(teacher => ({
              name: teacher.full_name,
              type: teacher._id,
            }))}
          />

          <FileField name="icon" label="Kurs rasmi" required />
        </div>
        {course ? (
          <LoadingButton isLoading={isCourseEditPending}>
            Tahrirlash
          </LoadingButton>
        ) : (
          <LoadingButton isLoading={isCourseCreatePending}>
            Saqlash
          </LoadingButton>
        )}
      </form>
    </Form>
  );
}
