export enum CategoryType {
  VOCATIONAL_TRAINING = 'VOCATIONAL_TRAINING',
  PERSONAL_DEVELOPMENT = 'PERSONAL_DEVELOPMENT',
}

export interface Category {
  _id: string;
  name: string;
  banner_image: string;
  description: string;
  type: CategoryType;
}

export interface CategoryCreate {
  name: string;
  banner_image: string;
  description: string;
  type: CategoryType;
}

export interface CategoryEdit {
  id: string;
  values: CategoryCreate;
}
