import { ProfileInfo, ResumeInfo, SubmittedResume } from './type';

export const getResume = (item: SubmittedResume) => {
  return {
    _id: item?._id ?? '',
    vacancy_status: item?.vacancy_status ?? '',
    profiles_info: getProfilesInfo(item?.profiles_info) ?? {},
    resumes_info: getResumesInfo(item?.resumes_info) ?? {},
  };
};

export const getResumeList = (data?: SubmittedResume[]) => {
  return data?.length
    ? data.map(item => {
        return getResume(item);
      })
    : [];
};

function getProfilesInfo(item: ProfileInfo) {
  return {
    user: item.user ?? '',
    first_name: item.first_name ?? '',
    last_name: item.last_name ?? '',
    image: item.image ?? '',
  };
}

function getResumesInfo(item: ResumeInfo) {
  return {
    full_name: item.full_name ?? '',
    job_title: item.job_title ?? '',
    exprience: item.exprience ?? '',
    description: item.description ?? '',
    file: item.file ?? '',
  };
}
