import { useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { useNewUsers } from "modules/statistics/hooks/useNewUsers";
import { DailyData } from "modules/statistics/types";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import Chart from "./Chart";
import { DateRangePicker } from "components/DataRangePicker";

export default function NewUsersChart() {
  const [date, setDate] = useState<DateRange>();
  const validDate = date?.from && date.to ? date : undefined;
  const { data: newUsers } = useNewUsers(validDate);

  const options = useMemo(() => {
    return {
      series: [
        {
          name: "Yangi foydalanuvchilar",
          data: newUsers?.map((item: DailyData) => item.count) ?? [],
        },
      ],
      chart: {
        height: 400,
        type: "bar",
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (value: number) {
          return value;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: newUsers?.map((item: DailyData) => item.date) ?? [],
        position: "bottom",
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
      },
      yaxis: {
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (value: number) {
            return value;
          },
        },
      },
    };
  }, [newUsers]);

  return (
    <Card className="col-span-4">
      <CardHeader>
        <div className="flex flex-col md:flex-row justify-between">
          <CardTitle className="text-xl md:text-2xl">
            Yangi foydalanuvchilar
          </CardTitle>
          <DateRangePicker
            date={date}
            setDate={setDate}
            className="mt-4 md:mt-0"
          />
        </div>
      </CardHeader>
      <CardContent className="pb-0">
        <Chart options={options} />
      </CardContent>
    </Card>
  );
}
