import { useToast } from "components/ui/use-toast";
import { EditComment } from "../api";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "services/react-query";
import { showErrorToast } from "utils/showErrorToast";
import { CommentsEdit } from "../types";

export const useEditComments = () => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: ({ id, is_hide }: CommentsEdit) => EditComment({ is_hide, id }),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyat!',
        description: 'Izoh tahrirlandi',
      });
      queryClient.invalidateQueries({ queryKey: ['comments_list'] });
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerCommentsEdit: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
