import { Vacancy } from './types';

export const getVacancy = (item?: Vacancy) => {
  return {
    _id: item?._id ?? '',
    title: item?.title ?? '',
    description: item?.description ?? '',
    company: item?.company ?? '',
    deadline: item?.deadline ?? new Date(),
    salary: item?.salary ?? '',
    address: item?.address ?? '',
    is_saved: item?.is_saved ?? false,
    submit_count: item?.submit_count ?? 0,
  };
};
export const getVacancyList = (data?: Vacancy[]) => {
  return data?.length
    ? data.map(item => {
        return getVacancy(item);
      })
    : [];
};
