import { useParams } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import { format } from 'date-fns';
import { useCommentsList } from 'modules/comments/hooks/useCommentsList';
import { useEditComments } from 'modules/comments/hooks/useEditComments';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Button } from 'components/ui/button';
import Loader from 'components/Loader';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { normalizeImgUrl } from 'utils/normalizeImgUrl';

const CourseComment = () => {
  const { courseId } = useParams();
  const { data: commentList, isLoading } = useCommentsList(courseId!);
  const { triggerCommentsEdit } = useEditComments();

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Izohlar ({commentList.length} ta)</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {commentList.length === 0 ? (
              <CardContent>Izohlar hali yo'q</CardContent>
            ) : (
              commentList?.map((coment, index) => (
                <div
                  className="flex items-center space-x-4 rounded-md border p-4"
                  key={index}
                >
                  <div className="size-8 overflow-hidden rounded-md">
                    <Avatar className="size-8">
                      <AvatarImage
                        src={normalizeImgUrl(coment.user_profile.image)}
                      />
                      <AvatarFallback>
                        {coment.user_profile.first_name.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                  </div>
                  <div className="flex-1 space-y-1">
                    <p className="text-sm font-medium leading-none">
                      {`${coment.user_profile.first_name} ${coment.user_profile.last_name}`}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {coment.comment_body}
                    </p>
                  </div>
                  <p className="text-sm  leading-none">
                    {format(coment.created_at, 'PPP')}
                  </p>
                  <Button
                    size="icon"
                    key={index}
                    onClick={() =>
                      triggerCommentsEdit({
                        id: coment.id,
                        is_hide: !coment.is_hide,
                      })
                    }
                  >
                    {coment?.is_hide ? <EyeOff size={20} /> : <Eye size={20} />}
                  </Button>
                </div>
              ))
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CourseComment;
