import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { getNewsList } from '../adapters';
import { GetNewsList } from '../api';
import { getPagination } from 'modules/adapter';

export const useNewsList = (currentPage: number) => {
  const initialData = {
    data: getNewsList(),
    paginationInfo: getPagination(),
  };
  const { data = initialData, ...args } = useQuery({
    queryKey: ['news_list'],
    queryFn: () => GetNewsList(currentPage),
    select: data => ({
      data: getNewsList(get(data, 'data.data')),
      paginationInfo: getPagination(get(data, 'data.pagination')),
    }),
  });

  return {
    ...data,
    ...args,
  };
};
