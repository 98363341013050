import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";

import { getCategoriesList } from "../adapters"
import { GetCategoriesList } from "../api";



export const useCategoriesList = ()=>{
    const initialData = {
        data: getCategoriesList()
    }
    const { data = initialData, ...args } = useQuery({
        queryKey: ['categories_list'],
        queryFn: () => GetCategoriesList(),
        select: data => ({
          data: getCategoriesList(get(data, 'data.data')),
        }),
      });
    
      return {
        ...data,
        ...args,
      };
}