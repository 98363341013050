import { useState } from 'react';
import { useCategoriesList } from 'modules/categories/hooks/useCategriesList';
import { TableActions } from 'components/TableActions';
import CategoryForm from './CategoryForm';
import Loader from 'components/Loader';
import { DataTable } from 'components/DataTable';
import { createCategoryColumns } from './Columns';
import { Category } from 'modules/categories/types';
import { useDeleteCategory } from 'modules/categories/hooks/useDeleteCategory';
import { Sheet } from 'components/Sheet';
import { AlertDialog } from 'components/AlertDialog';

const Categories = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [category, setCategoty] = useState<Category>();
  const { data: categoriesList, isLoading } = useCategoriesList();
  const { triggerCategoryDelete } = useDeleteCategory(category?._id!);

  const getRowData = (category: Category) => {
    setCategoty(category);
  };

  const columns = createCategoryColumns({
    getRowData,
    setDialogOpen,
    setSheetOpen,
  });

  return (
    <div>
      <TableActions
        sheetTriggerTitle="Toifa qo'shish"
        sheetTitle="Yangi Toifa qo'shish."
        TableForm={CategoryForm}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable
          columns={columns}
          data={categoriesList}
          navigateTable={false}
        />
      )}

      <Sheet
        sheetTitle="Bo'limni tahrirlash"
        isOpen={isSheetOpen}
        setSheetOpen={setSheetOpen}
      >
        <CategoryForm category={category} setSheetOpen={setSheetOpen} />
      </Sheet>
      <AlertDialog
        alertTitle="Ishonchingiz komilmi?"
        alertDescription="Bu harakat orqali siz ma'lumotni o'chirib tashlaysiz."
        alertCancel="Bekor qilish"
        alertActionTitle="Davom etish"
        alertActionFunction={triggerCategoryDelete}
        isOpen={isDialogOpen}
        setIsOpen={setDialogOpen}
      />
    </div>
  );
};

export default Categories;
