import http from 'services/api';
import { TeacherCreate, TeacherEdit } from './types';
export const GetTeachersList = async (currentPage: number) => {
  return await http.get(`/teacher?page=${currentPage}`);
};

export const CreateTeacher = async (values: TeacherCreate) => {
  return await http.post(`/teacher/`, values);
};
export const EditTeacher = async ({ values, id }: TeacherEdit) => {
  return await http.put(`/teacher/${id}`, values);
};
export const DeleteTeacher = async (id: string) => {
  return await http.delete(`/teacher/${id}`);
};
