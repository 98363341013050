export interface Vacancy {
  _id: string;
  title: string;
  description: string;
  company: string;
  deadline: Date;
  salary: string;
  address: string;
  is_saved: boolean;
  submit_count: number;
}
export interface VacancyBody {
  title: string;
  description: string;
  company: string;
  deadline: Date;
  salary: string;
  address: string;
}

export interface VacancyEdit {
  id: string;
  values: VacancyBody;
}

export enum VacancyStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}
