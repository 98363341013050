import { ColumnDef } from '@tanstack/react-table';
import { Story } from 'modules/stories/types';
import { DataTableRowActions } from 'components/DataTableRowActions';
import { Link } from 'react-router-dom';

interface IProps {
  getRowData: (story: Story) => void;
  setSheetOpen: (state: boolean) => void;
  setDialogOpen: (state: boolean) => void;
}

export const createStoryColumns = ({
  getRowData,
  setSheetOpen,
  setDialogOpen,
}: IProps): ColumnDef<Story>[] => [
  {
    accessorKey: 'title',
    header: 'Hikoya nomi',
  },
  {
    accessorKey: 'link',
    header: 'Hikoya havolasi',
    cell: ({ row }) => (
      <Link
        to={row.getValue('link')}
        className="hover:underline text-blue-500"
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        Havola
      </Link>
    ),
  },
  {
    accessorKey: '_id',
    header: () => <span className="sr-only">Actions</span>,
    size: 50,
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        getRowData={getRowData}
        setDialogOpen={setDialogOpen}
        setSheetOpen={setSheetOpen}
      />
    ),
  },
];
