import { useMutation } from '@tanstack/react-query';

import { useToast } from 'components/ui/use-toast';
import { ReplyResume } from '../api';
import { ResumeInput } from '../type';
import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';

interface IHook {
  vacancyId: string;
  setIsOpen: (state: boolean) => void;
}

export const useReplyResume = ({ vacancyId, setIsOpen }: IHook) => {
  const { toast } = useToast();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: (values: ResumeInput) => ReplyResume(vacancyId, values),
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Muvaffaqiyatli bajarildi!',
      });
      queryClient.invalidateQueries({ queryKey: ['resumes_list'] });
      setIsOpen(false);
    },
    onError: (error: any) => showErrorToast(error),
  });

  return {
    triggerReplyResume: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
