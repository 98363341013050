import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';


import { queryClient } from 'services/react-query';
import { showErrorToast } from 'utils/showErrorToast';
import { EditeVacancy } from "../api";
import { VacancyBody, VacancyEdit } from "../types";


interface IHook {
    id?: string;
    setSheetOpen: (state: boolean) => void;
  }
  
  export const useEditVacncy = ({ id = '', setSheetOpen }: IHook) => {
    const { toast } = useToast();
  
    const { mutate, isPending, isSuccess, isError } = useMutation({
      mutationFn: (values: VacancyBody) => EditeVacancy({ values, id }),
      onSuccess: () => {
        toast({
          variant: 'success',
          title: 'Muvaffaqiyat!',
          description: 'Vakansiya muvaffaqiyatli tahrirlandi.',
        });
        queryClient.invalidateQueries({ queryKey: ['vacancy_list'] });
        setSheetOpen(false);
      },
      onError: (error: any) => showErrorToast(error),
    });
  
    return {
      triggerVacancyEdit: mutate,
      isPending,
      isSuccess,
      isError,
    };
  };