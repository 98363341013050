import React, { useContext } from "react";

import MainLayout from "layout/MainLayout";
import AuthLayout from "layout/AuthLayout";
import { Routes as DOMRoutes, Route } from "react-router-dom";
import { Toaster } from "components/ui/toaster";
import { useRefreshToken } from "modules/auth/hooks/useRefreshToken";
import AuthPage from "pages/Auth";
import CoursesPage from "pages/Courses";
import ModulesPage from "pages/Modules";
import LessonsPage from "pages/Lessons";
import QuizzesPage from "pages/Quizzes";
import NewsPage from "pages/News";
import TeachersPage from "pages/Teachers";
import VacanciesPage from "pages/Vacancies";
import CategoriesPage from "pages/Categories";
import NotificationsPage from "pages/Notifications";
import CommentsPage from "pages/Comments";
import CourseCommentPage from "pages/Comments/CourseComment";
import StoriesPage from "pages/Stories";
import ResumePage from "pages/Resume";
import { AuthContext } from "providers/auth";
import Home from "pages/Home";

export const Routes = () => {
  const { isLoading } = useRefreshToken();
  const { isAuthenticated } = useContext(AuthContext);
  const routes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/courses",
      element: <CoursesPage />,
    },
    {
      path: "/courses/:courseId",
      element: <ModulesPage />,
    },
    {
      path: "/courses/:courseId/:moduleId",
      element: <LessonsPage />,
    },
    {
      path: "/courses/:courseId/:moduleId/:lessonId",
      element: <QuizzesPage />,
    },
    {
      path: "/news",
      element: <NewsPage />,
    },
    {
      path: "/vacancies",
      element: <VacanciesPage />,
    },
    {
      path: "/vacancies/:vacancyId",
      element: <ResumePage />,
    },
    {
      path: "/categories",
      element: <CategoriesPage />,
    },
    {
      path: "/teachers",
      element: <TeachersPage />,
    },
    {
      path: "/stories",
      element: <StoriesPage />,
    },
    {
      path: "/comments",
      element: <CommentsPage />,
    },
    {
      path: "/comments/:courseId",
      element: <CourseCommentPage />,
    },
    {
      path: "/notifications",
      element: <NotificationsPage />,
    },
  ];

  return (
    <>
      {isLoading ? null : isAuthenticated ? (
        <MainLayout>
          <DOMRoutes>
            {routes.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}
          </DOMRoutes>
        </MainLayout>
      ) : (
        <AuthLayout>
          <DOMRoutes>
            <Route path="/" element={<AuthPage />} />
            <Route path="*" element={<AuthPage />} />
          </DOMRoutes>
        </AuthLayout>
      )}
      <Toaster />
    </>
  );
};
