import { ColumnDef } from '@tanstack/react-table';
import { Course } from 'modules/courses/types';
import { Play } from 'lucide-react';

export const createCommentColumns = (): ColumnDef<Course>[] => [
  {
    accessorKey: 'name',
    header: 'Kurs nomi',
  },
  {
    accessorKey: 'short_description',
    header: 'Tavsifi',
  },
  {
    accessorKey: 'lessons_count',
    header: 'Dars soni',
    cell: ({ row }) => (
      <div className="flex items-center gap-1">
        <Play className="w-4 h-4 stroke-1" />
        {row.getValue('lessons_count')}
      </div>
    ),
  },
];
